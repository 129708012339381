var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "vue-code-highlight",
        { staticClass: "prism", attrs: { language: _vm.language } },
        [_vm._v(" " + _vm._s(_vm.code) + " ")]
      ),
      _c(
        "div",
        { staticClass: "copy" },
        [
          _c("copy-to-clipboard", {
            attrs: {
              textToCopy: _vm.code,
              buttonClass: "caption font-weight-bold",
              buttonName: "copy to clipboard",
              iconButton: true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }